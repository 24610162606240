.member-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 140px; /* Set a fixed width for uniformity */
}

.member-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
}

.member-info p {
  margin: 2px 0; /* Reduce vertical spacing between name and email */
  text-align: center; /* Ensure text is centered */
}

.avatar {
  width: 64px;
  height: 64px;
  background-color: #f0f0f0; /* Fallback color if image fails to load */
}

.owner-tag {
  visibility: hidden; /* Hidden by default */
}

.member-container.owner .owner-tag {
  margin-top: 8px;
  visibility: visible; /* Visible when member is the owner */
  font-weight: bold;
}

.member-delete {
  margin-top: 8px;
}

.card-container {
  position: relative;
  overflow: hidden;
}

.card-container .icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card-container:hover .icon-container {
  opacity: 1;
}
